import {
  StyledLightbox,
  StyledLightboxContainer,
  CloseIconButton,
} from "./styles";
import CloseIcon from "../../assets/closeIcon.svg";
const Lightbox = ({
  children,
  open,
  onClose,
}: {
  children: any;
  open: any;
  onClose: any;
}) => {
  const handleClick = () => {
    onClose(false);
  };
  return (
    <StyledLightbox open={open} onClose={onClose}>
      <CloseIconButton onClick={handleClick}>
        {" "}
        <img src={CloseIcon} alt="Fechar" />
      </CloseIconButton>
      <StyledLightboxContainer>{children}</StyledLightboxContainer>
    </StyledLightbox>
  );
};

export { Lightbox };

import { Error } from "components/Error";
import { Container } from "./styles";

const NotFound = () => {
  return (
    <Container>
      <Error message="Página não encontrada" />
    </Container>
  );
};

export { NotFound };

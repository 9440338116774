import { Quiz } from "pages/Quiz";
import { NotFound } from "pages/NotFound";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ResultsTable } from "pages/ResultsTable";
import { SharedResult } from "pages/SharedResult";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:quizId/" element={<Quiz />} />
        <Route path="/:quizId/:answerId/results" element={<ResultsTable />} />
        <Route path="/:quizId/results/:resultId" element={<SharedResult />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export { Router };

import { styled } from "@mui/system";

export const QuizContanier = styled("div")`
  margin: 24px 24px;
  ${(props) => props.theme.breakpoints.up("sm")} {
    margin: 36px 120px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    margin: 72px 240px;
  }
`;

export const QuestionsContainer = styled("div")`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 88px;
  }
`;

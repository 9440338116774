import {
  Container,
  Title,
  QuestionsBox,
  RightAnswersBox,
  WrongAnswersBox,
  ShareResultsButton,
  ResultsQuantity,
  BoxContainer,
  NewTestButton,
} from "./styles";
import ShareIcon from "../../assets/shareIcon.svg";
import LinkedinIcon from "../../assets/linkedinIcon.svg";
import TwitterIcon from "../../assets/twitterIcon.svg";
import FacebookIcon from "../../assets/facebookIcon.svg";
import WhatsappIcon from "../../assets/whatsappIcon.svg";
import { useQuestionsStore } from "store/questions";
import { useNavigate } from "react-router-dom";
import { quizDominio } from "utils/constants";
import { useQuizStore } from "store/quiz";
interface ResultBoxProps {
  newTest?: boolean;
  quizId: string;
}

const ResultsBox = ({ newTest, quizId }: ResultBoxProps) => {
  const navigate = useNavigate();
  const { totalQuestions, wrongAnswers, rightAnswers, resultCode } =
    useQuestionsStore();
  const { url } = useQuizStore();

  const urlResult = `${window.location.origin}/${quizId}/results/${resultCode}`;

  const handleLocation = () => {
    window.location.replace(url);
  };

  return (
    <Container>
      <BoxContainer>
        <QuestionsBox>
          <Title>Perguntas</Title>
          <ResultsQuantity color="#fff">{totalQuestions}</ResultsQuantity>
        </QuestionsBox>
        <RightAnswersBox>
          <Title>Corretas</Title>
          <ResultsQuantity color="#63D260">{rightAnswers}</ResultsQuantity>
        </RightAnswersBox>
        <WrongAnswersBox>
          <Title>Incorretas</Title>
          <ResultsQuantity color="#FF8383">{wrongAnswers}</ResultsQuantity>
        </WrongAnswersBox>
      </BoxContainer>
      {newTest && quizId ? (
        <NewTestButton onClick={() => handleLocation()}>
          Testar meu conhecimento
        </NewTestButton>
      ) : (
        <ShareResultsButton>
          <span>
            {" "}
            <img src={ShareIcon} alt="compartilhe o seu resultado" />
            <span>Compartilhe o seu resultado</span>
          </span>
          <div className="container">
            <a
              href={`https://www.linkedin.com/feed/?shareActive=true&text=Acabei de realizar uma quiz da Full Cycle sobre Desenvolvimento e Arquitetura de software. Veja meu resultado. ${urlResult} %23NewPost`}
            >
              <img
                src={LinkedinIcon}
                alt="Compartilhe o resultado do Quiz no LinkedIn"
              ></img>
            </a>
            <a
              href={`https://twitter.com/intent/tweet?url=${urlResult}&text=Resultados do Quiz!`}
            >
              <img
                src={TwitterIcon}
                alt="Compartilhe o resultado do Quiz no Twitter"
              ></img>
            </a>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${urlResult}`}
            >
              <img
                src={FacebookIcon}
                alt="Compartilhe o resultado do Quiz no Twitter"
              ></img>
            </a>
            <a
              href={`https://api.whatsapp.com/send?text=Resultados do Quiz!%20${urlResult}`}
            >
              <img
                src={WhatsappIcon}
                alt="Compartilhe o resultado do Quiz no WhatsApp"
              ></img>
            </a>
          </div>
        </ShareResultsButton>
      )}
    </Container>
  );
};

export { ResultsBox };

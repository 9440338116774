import { ErrorProps } from "./types";
import ErrorIcon from "assets/error.gif";
import { ErrorTitle, ErrorContainer, ErrorImage } from "./styles";

const Error = ({ message }: ErrorProps) => {
  return (
    <ErrorContainer>
      <ErrorImage src={ErrorIcon} alt="error" />
      <ErrorTitle>{message ? message : "Ops...Algo deu errado."}</ErrorTitle>
    </ErrorContainer>
  );
};

export { Error };

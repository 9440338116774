import { create } from "zustand";
import { questionsStoreProps, useQuestionsStoreProps } from "./types";

const store: questionsStoreProps = {
  totalQuestions: 0,
  answers: 0,
  rightAnswers: 0,
  wrongAnswers: 0,
  categories: [],
  resultCode: "",
};

export const useQuestionsStore = create<useQuestionsStoreProps>((set) => ({
  ...store,
  setAnswers: (answers) => set({ answers: answers }),
  setRightAnswers: (rightAnswers) => set({ rightAnswers: rightAnswers }),
  setTotalQuestions: (totalQuestions) =>
    set({ totalQuestions: totalQuestions }),
  setWrongAnswers: (wrongAnswers) => set({ wrongAnswers: wrongAnswers }),
  setCategories: (categories) => set({ categories: categories }),
  setResultCode: (resultCode) => set({ resultCode: resultCode }),
}));

import { Box } from "@mui/material";
import { CategoryProps } from "services/hooks/useGetEmailResult/types";
import { calcProgress } from "utils/calcProgress";
import errorCircle from "assets/close-circle.svg";

import {
  CourseProgressContainer,
  Infos,
  ProgressIcon,
  Answers,
  StyledProgress,
  Question,
  RightAnswer,
} from "./styles";
import { Category } from "services/postAnswers";

export interface CourseProgressProps extends Category {}

export const CourseProgress = ({
  icon,
  name,
  rightAnswers,
  totalQuestions,
  correctAnswers,
}: CourseProgressProps) => {
  return (
    <CourseProgressContainer>
      <Infos>
        <p>{name}</p>
        <p>
          {rightAnswers} de {totalQuestions}
        </p>
      </Infos>
      <>
        <StyledProgress
          variant="determinate"
          value={calcProgress(totalQuestions, rightAnswers)}
        />
        <Box marginX={`${calcProgress(totalQuestions, rightAnswers) - 4}%`}>
          <ProgressIcon>
            <img src={icon} alt="icone de progresso" />
          </ProgressIcon>
        </Box>
      </>
      <Answers>
        {correctAnswers.length > 0 ? <h5>Incorretas:</h5> : null}
        {correctAnswers.map((answer, i) => (
          <div key={i}>
            <div>
              <img src={errorCircle} alt="" />
            </div>
            <div>
              <Question>
                {answer.number} - {answer.question}:
              </Question>
              <span>Resposta correta:</span>
              <RightAnswer>{answer.answer}</RightAnswer>
            </div>
          </div>
        ))}
      </Answers>
    </CourseProgressContainer>
  );
};

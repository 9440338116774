import { CourseProgress } from "components/CourseProgress";
import {
  Container,
  ButtonContainer,
  QuizTitle,
  RedeemPoints,
  CloseLightboxButton,
  SuccessImage,
} from "./styles";
import { DetailedResultsProps } from "./types";
import { useQuizStore } from "store/quiz";
import { ResultsBox } from "components/ResultsBox";
import { useParams } from "react-router-dom";
import { useQuestionsStore } from "store/questions";
import { HubspotForm } from "components/HubspotForm";
import { useState } from "react";
import { Lightbox } from "components/Lightbox";
import SuccessAnimation from "../../assets/success.gif";

export const DetailedResults = () => {
  const { title, logo, eventAdvertising } = useQuizStore();

  const { categories } = useQuestionsStore();

  const { quizId } = useParams();

  const [open, setOpen] = useState<boolean>(true);

  const [isFormSuccess, setFormSuccess] = useState(false);

  const handleDialog = () => {
    setOpen(false);
  };

  return (
    <Container>
      {eventAdvertising.isActive && (
        <Lightbox onClose={handleDialog} open={open}>
          {eventAdvertising.text && !isFormSuccess ? (
            <div dangerouslySetInnerHTML={{ __html: eventAdvertising?.text }} />
          ) : null}
          {isFormSuccess && (
            <SuccessImage src={SuccessAnimation} alt="Sucesso!" />
          )}
          <HubspotForm handleFormSucess={setFormSuccess} />
          {isFormSuccess && (
            <CloseLightboxButton onClick={handleDialog}>
              Fechar
            </CloseLightboxButton>
          )}
        </Lightbox>
      )}
      <main>
        <div>
          <img
            src={logo}
            alt="Full Cycle Learning Experience Logo"
            title="Full Cycle Learning Experience"
          />
          <QuizTitle>{title}</QuizTitle>
          <ButtonContainer>{/* <Result /> */}</ButtonContainer>
        </div>
        <div>
          <ResultsBox quizId={quizId as string} />
        </div>
      </main>
      <aside>
        {!!categories &&
          categories.map((result, i) => <CourseProgress {...result} key={i} />)}
      </aside>
    </Container>
  );
};

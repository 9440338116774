import { create } from "zustand";
import { UserInfoProps, useUserInfoStoreProps } from "./types";

const store: UserInfoProps = {
  email: "",
};

export const useUserInfoStore = create<useUserInfoStoreProps>((set) => ({
  ...store,
  setUserInfo: (email) => {
    set({ email: email });
  },
}));

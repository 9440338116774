import { AxiosRequestConfig } from "axios";
import { api } from "services";
import React from "react";

const http = async <T = any>(
  url: string,
  options: AxiosRequestConfig
): Promise<T> => {
  const { data } = await api.request({
    url,
    ...options,
  });
  return data;
};

export const useHttp = <T = any>(url: string, options: any) => {
  const [loading, setLoading] = React.useState(false);
  const request = React.useCallback(
    async (data?: T) => {
      setLoading(true);
      return await http<T>(url, {
        ...options,
        data,
      })
      .finally(() => setLoading(false));
    },
    [options, url]
  );
  return {
    loading,
    request,
  };
};

import { useEffect } from "react";
import "./hubspot.css";
import { useQuizStore } from "store/quiz";
import { useUserInfoStore } from "store/user";

const HubspotForm = ({ handleFormSucess }: { handleFormSucess: any }) => {
  const { eventAdvertising } = useQuizStore();
  const { email } = useUserInfoStore();

  const postFormSubmition = () => {
    handleFormSucess(true);
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: eventAdvertising.formPortalId,
          formId: eventAdvertising.formId,
          target: "#hubspotForm",
          cssRequired: " ",
          errorMessageClass: "custom-errors",
          submitButtonClass: "custom-submit",
          cssClass: "custom-hubspot-form",
          translations: {
            "pt-br": {
              required: " ",
              missingOptionSelection: " ",
              missingSelect: " ",
              submissionErrors: {
                MISSING_REQUIRED_FIELDS: "Por favor, preencha todos os campos",
              },
            },
          },
          onFormReady: (form: any) => {
            const inputEmail = form.querySelector('input[name="email"]');
            if (inputEmail) {
              inputEmail.focus({ focus: false });
              const event = new Event("change", { bubbles: true });
              inputEmail.value = email;
              inputEmail.dispatchEvent(event);
            } else {
              console.error(
                'Nenhum input com o atributo name="email" encontrado dentro do formulário.'
              );
            }
          },
          onFormSubmitted: (form: any) => {
            postFormSubmition();
          },
        });
      }
    });
  }, []);

  return (
    <div>
      <div id="hubspotForm"></div>
    </div>
  );
};

export { HubspotForm };

import { Radio, RadioGroup, FormControl } from "@mui/material";
import {
  StyledForm,
  StyledFormLabel,
  QuestionNumber,
  QuestionTitle,
  QuestionContainer,
  StyledFormControlLabel,
  StyledIcon,
  StyledCheckedIcon,
  StyledLabel,
  CustomButton,
  ErrorWarning,
  ErrorMessage,
} from "./styles";
import React, { useEffect, useState } from "react";
import ArrowRightIcon from "../../assets/arrowRight.svg";
import { QuestionsProps } from "./types";
import { useQuestionsStore } from "store/questions";
import { useForm, Controller } from "react-hook-form";
import { postAnswers } from "services/postAnswers";
import { Loading } from "components/Loading";
import { Error } from "components/Error";
import { useNavigate, useParams } from "react-router-dom";
import { quizDominio } from "utils/constants";
import { useQuizStore } from "store/quiz";
import { useUserInfoStore } from "store/user";

const Questions = ({ questions }: QuestionsProps) => {
  const { quizId } = useParams<{ quizId: string }>();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const { url: quizUrl } = useQuizStore();

  const { setUserInfo } = useUserInfoStore();

  const handleUrl = async () => {
    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    const email = urlParams.get("email")?.replace(" ", "+");
    if (email === null || email === undefined) {
      window.location.replace(quizUrl);
    } else {
      setEmail(email);
      setUserInfo(email);
    }
  };

  useEffect(() => {
    handleUrl();
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const {
    setAnswers,
    setRightAnswers,
    setWrongAnswers,
    setCategories,
    setResultCode,
  } = useQuestionsStore();

  const [status, setStatus] = useState<
    "idle" | "pending" | "resolved" | "rejected"
  >("idle");

  const [selectedAnswers, setSelectedAnswers] = useState<
    {
      questionId: string;
      answerId: string;
    }[]
  >([]);

  function handleAnswerSelection(
    questionId: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const isQuestionAnswered = selectedAnswers.some(
      (selectedAnswer) => selectedAnswer.questionId === questionId
    );

    if (isQuestionAnswered) {
      const newSelectedAnswers = selectedAnswers.map((selectedAnswer) => {
        if (selectedAnswer.questionId === questionId) {
          return { questionId: questionId, answerId: event.target.value };
        }
        return selectedAnswer;
      });
      setSelectedAnswers(newSelectedAnswers);
    } else {
      setSelectedAnswers([
        ...selectedAnswers,
        { questionId: questionId, answerId: event.target.value },
      ]);
    }
  }

  useEffect(() => {
    setAnswers(selectedAnswers.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnswers]);

  const onSubmit = (data: any) => {
    let answers: { question_id: string; answer_id: any }[] = [];
    Object.keys(data).map((key, i) => {
      answers.push({
        question_id: key,
        answer_id: data[key],
      });
    });

    setStatus("pending");
    postAnswers(quizId as string, { questions: answers, email: email })
      .then((data) => {
        if (data) {
          setStatus("resolved");
          setRightAnswers(data.data.rightAnswers);
          setWrongAnswers(data.data.wrongAnswers);
          setCategories(data.data.categories);
          setResultCode(data.data.resultCode);
          navigate(`${data.data.resultCode}/results`);
        }
      })
      .catch((error) => {
        setStatus("rejected");
      });
  };

  return (
    <QuestionContainer>
      {status === "idle" && (
        <>
          <StyledForm
            id="quiz"
            style={{}}
            onSubmit={handleSubmit(onSubmit)}
            aria-label="form"
          >
            {questions.map((question, index) => {
              return (
                <FormControl
                  key={question.id}
                  component="fieldset"
                  style={{ marginBottom: "48px", width: "100%" }}
                >
                  <StyledFormLabel focused={false}>
                    {errors[question.id] && <ErrorWarning>!</ErrorWarning>}
                    <QuestionNumber>
                      {index < 9 ? `0${index + 1}` : index + 1}
                    </QuestionNumber>
                    <QuestionTitle>{question.question}</QuestionTitle>
                  </StyledFormLabel>
                  <Controller
                    control={control}
                    name={question.id}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <RadioGroup
                        aria-labelledby="questions-radio-buttons-group-label"
                        {...field}
                        onChange={(event) => {
                          handleAnswerSelection(question.id, event);
                          field.onChange(event.target.value);
                        }}
                      >
                        {question.answers.map((answer) => {
                          return (
                            <StyledFormControlLabel
                              key={answer.id}
                              value={answer.id}
                              checked={selectedAnswers.some(
                                (selectedAnswer) =>
                                  selectedAnswer.questionId === question.id &&
                                  selectedAnswer.answerId === answer.id
                              )}
                              control={
                                <Radio
                                  disableRipple
                                  icon={<StyledIcon />}
                                  checkedIcon={<StyledCheckedIcon />}
                                />
                              }
                              label={<StyledLabel>{answer.answer}</StyledLabel>}
                            />
                          );
                        })}
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              );
            })}
          </StyledForm>
          {Object.keys(errors).length > 0 && (
            <ErrorMessage>
              Para enviar o quiz, você precisa responder todas as questões.
            </ErrorMessage>
          )}
          <CustomButton
            type="submit"
            form="quiz"
            disabled={Object.keys(errors).length > 0}
          >
            Enviar
          </CustomButton>
        </>
      )}
      {status === "pending" && <Loading message="Enviando Respostas" />}
      {status === "rejected" && <Error message="Ops...Algo deu errado" />}
    </QuestionContainer>
  );
};

export { Questions };

import { create } from "zustand";
import { quizStoreProps, useQuizStoreProps } from "./types";

const store: quizStoreProps = {
  title: "",
  logo: "",
  description: "",
  url: "",
  eventAdvertising: {
    isActive: false,
    text: "",
    formId: "",
    formPortalId: "",
  },
};

export const useQuizStore = create<useQuizStoreProps>((set) => ({
  ...store,
  setQuizStatus: (title, logo, description, url, eventAdvertising) =>
    set({
      title: title,
      logo: logo,
      description: description,
      url: url,
      eventAdvertising: eventAdvertising,
    }),
}));

import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const HeaderContainer = styled("div")`
  padding-bottom: 16px;
  border-bottom: 1px solid #2c2c2c;
  min-width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled("h2")`
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 16px;

  @media (min-width: 360px) {
    font-size: 38px;
  }

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 40px;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    font-size: 48px;
  }
`;

export const ProgressQuantity = styled(Typography)`
  font-size: 16px;
  color: #fff;
  font-weight: 700;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1.8rem;
    line-height: normal;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    font-size: 2.2rem;
    line-height: 1.167;
  }
`;

export const InfosContainer = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  & h3 {
    min-width: fit-content;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    column-gap: 20px;
    & h3 {
      font-size: 16px;
      min-width: fit-content;
    }
  }
`;
export const QuizResultsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;
`;

export const LogoContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  & > img {
    margin: 0 auto 32px auto;
    max-width: 160px;
    min-width: 160px;
    height: 48px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    width: auto;
    justify-content: center;
    align-items: flex-start;
    & > img {
      margin: unset;
      max-width: 240px;
      min-width: 240px;
      height: 72px;
      margin-right: 48px;
      margin-bottom: 24px;
    }
  }
`;

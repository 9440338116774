import { LoadingProps } from "./types";
import LoadingIcon from "../../assets/loading.gif";
import { LoadingTitle, LoadingContainer, LoadingImage } from "./styles";

const Loading = ({ message }: LoadingProps) => {
  return (
    <LoadingContainer>
      <LoadingImage src={LoadingIcon} alt="carregando" />
      <LoadingTitle>{message ? message : "Carregando"}</LoadingTitle>
    </LoadingContainer>
  );
};

export { Loading };

import { styled } from "@mui/material/styles";

export const LoadingContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoadingTitle = styled("h3")`
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  text-align: center;
`;

export const LoadingImage = styled("img")`
  max-width: 200px;
  max-height: 200px;

  ${(props) => props.theme.breakpoints.up("xl")} {
    max-width: 368px;
    max-height: 368px;
  }
`;

import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#FFCD00",
    },
    background: {
      default: "#1E1E1E",
    },
  },
  typography: {
    h2: {
      fontSize: "2.50rem",
      fontWeight: 700,
      userSelect: "none",
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 700,
      userSelect: "none",
    },
  },
});

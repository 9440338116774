import { styled } from "@mui/material";

export const Container = styled("div")`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 0 16px;
`;

export const BoxContainer = styled("div")`
  border-radius: 6px;
  border: 1px solid rgba(112, 112, 112, 0.3);
  background: #303030;
  display: flex;
  max-width: 300px;
  width: 100%;
  max-height: 117px;
  padding: 24px 29px;
  box-sizing: border-box;

  @media (min-width: 360px) {
    max-width: 325px;
  }
`;

export const QuestionsBox = styled("div")`
  display: flex;
  flex-direction: column;
  margin-right: 30px;

  @media (min-width: 360px) {
    margin-right: 40px;
  }
`;

export const RightAnswersBox = styled("div")`
  display: flex;
  flex-direction: column;
  max-height: 117px;
  margin-right: 28px;

  @media (min-width: 360px) {
    margin-right: 28px;
  }
`;

export const WrongAnswersBox = styled("div")`
  display: flex;
  flex-direction: column;
  max-height: 117px;
`;

export const Title = styled("h5")`
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  margin: 0 0 8px 0;

  @media (min-width: 360px) {
    font-size: 16px;
  }
`;

export const ResultsQuantity = styled("p")<{ color: string }>`
  color: ${(props) => props.color};
  font-family: "Source Sans Pro", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  line-height: 42px;

  @media (min-width: 360px) {
    font-size: 40px;
  }
`;

export const ShareResultsButton = styled("button")`
  height: 45px;
  width: 100%;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 6px;
  border: 1px solid #fff;
  span {
    z-index: 1;
    display: flex;
    background-color: transparent;
    height: 40px;
    border-radius: 25px;
    color: #fff;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: all 0.5s;
    align-items: center;
    justify-content: center;
  }

  .container {
    z-index: -1;
    width: 0;
    position: relative;
    display: flex;
    justify-content: center;
    transform: translateY(-40px);
    transition: all 0.4s;
    column-gap: 30px;

    a {
      display: none;
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  &:hover span {
    width: 0;
    * {
      display: none;
    }
  }

  &:hover .container {
    z-index: 2;
    width: 100%;

    a {
      display: flex;
    }
  }
`;

export const NewTestButton = styled("button")`
  height: 45px;
  width: 100%;
  background-color: #fcca50;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
`;

import { styled } from "@mui/material/styles";

export const ErrorContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("xl")} {
    padding-top: 50px;
  }
`;

export const ErrorTitle = styled("h3")`
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  margin: 15px 0 0 0;
  text-align: center;

  ${(props) => props.theme.breakpoints.up("xl")} {
    width: 252px;
    height: 252px;
    margin: 32px 0 0 0;
  }
`;

export const ErrorImage = styled("img")`
  max-width: 150px;
  max-height: 150px;

  ${(props) => props.theme.breakpoints.up("xl")} {
    width: 252px;
    height: 252px;
  }
`;

import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";

export const StyledLightbox = styled(Dialog)`
  .MuiDialog-container {
    align-items: flex-start;

    @media (min-width: 960px) {
      align-items: center;
    }
  }

  .MuiDialog-paper {
    background: #202020;
    border: 1px solid rgba(106, 106, 106, 0.5);
    padding: 0;
    position: relative;
    max-width: 600px;
    width: 100%;
    padding: 48px 24px;
    max-height: unset;
    height: fit-content;
    border-radius: 10px;
    margin: 24px 16px;

    @media (min-width: 960px) {
      margin: 0;
    }
  }
`;

export const StyledLightboxContainer = styled("div")`
  width: 100%;
`;

export const CloseIconButton = styled("button")`
  position: absolute;
  right: 24px;
  top: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

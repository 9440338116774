import { useHttp } from "../index";
import { useGetSharedResultsResponseProps } from "./types";

export const useGetSharedResults = (quizId: string, resultId: string) =>
  useHttp<useGetSharedResultsResponseProps>(
    `/quiz/${quizId}/results/${resultId}/`,
    {
      method: "get",
    }
  );

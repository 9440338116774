import { Error } from "components/Error";
import { Loading } from "components/Loading";
import { ResultsBox } from "components/ResultsBox";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetQuizStatus } from "services/hooks/useGetQuizStatus";
import { useQuizStore } from "store/quiz";
import { Container } from "./styles";
import { useGetSharedResults } from "services/hooks/useGetSharedResults";
import { useQuestionsStore } from "store/questions";

export const SharedResult = () => {
  const params = useParams<{
    quizId: string;
    answerId: string;
    resultId: string;
  }>();

  const { setQuizStatus, title, logo } = useQuizStore();
  const { setTotalQuestions, setWrongAnswers, setRightAnswers } =
    useQuestionsStore();

  const navigate = useNavigate();

  const { loading: loadingStatus, request: requestStatus } = useGetQuizStatus(
    params.quizId as string
  );

  const { loading: loadingSharedResults, request: requestSharedResults } =
    useGetSharedResults(params.quizId as string, params.resultId as string);

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (title) {
      document.title = title;
    }

    const metaDescription = document.head.querySelector(
      'meta[name="description"]'
    );
    metaDescription?.setAttribute(
      "content",
      "Acabei de realizar uma quiz da Full Cycle sobre Desenvolvimento e Arquitetura de software. Veja meu resultado."
    );
  }, [title]);

  useEffect(() => {
    setLoading(true);
    requestStatus()
      .then((data) => {
        if (!data.active) {
          navigate(`/${params.quizId}`);
        } else {
          requestSharedResults()
            .then((resultsData) => {
              setTotalQuestions(resultsData.totalQuestions);
              setRightAnswers(resultsData.correctAnswers);
              setWrongAnswers(resultsData.wrongAnswers);
            })
            .catch((e) => setError(true));
          setQuizStatus(data.title, data.logo, data.description, data.url, {
            isActive: data.eventAdvertising.isActive,
            text: data.eventAdvertising.text,
            formId: data.eventAdvertising.formId,
            formPortalId: data.eventAdvertising.formPortalId,
          });
        }
      })
      .catch((error) => {
        setError(true);
      });
  }, []);

  useEffect(() => {
    setLoading(loadingStatus || loadingSharedResults);
  }, [loadingStatus, loadingSharedResults]);

  return (
    <Container>
      {loading && !error && <Loading />}
      {error && !loading && <Error />}
      {title && !loading && !error && (
        <>
          <img src={logo} alt="Logo" className="logo" />
          <h1>{title}</h1>
          <p>Resultado</p>
          <ResultsBox newTest quizId={params.quizId as string} />
        </>
      )}
    </Container>
  );
};

import { styled } from "@mui/material";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px 24px;
  align-content: center;
  width: 100%;
  max-width: calc(100vw - 48px);
  min-height: 120px;
  height: calc(100vh - 48px);
  & .logo {
    max-height: 80px;
  }
  & h1 {
    font-weight: 700;
    font-size: 32px;
    margin: 32px 0 16px;
    text-align: center;
    ${(props) => props.theme.breakpoints.up("md")} {
      text-align: left;
      font-size: 35px;
    }
    ${(props) => props.theme.breakpoints.up("lg")} {
      font-size: 40px;
    }
    ${(props) => props.theme.breakpoints.up("xl")} {
      font-size: 50px;
    }
  }
  & p {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    margin-top: 0;
  }
`;

import { DetailedResults } from "components/DetailedResults";
import { Loading } from "components/Loading";
import { results } from "mocks/data/results";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetEmailResult } from "services/hooks/useGetEmailResult";
import { useGetEmailResultsProps } from "services/hooks/useGetEmailResult/types";
import { useGetQuizStatus } from "services/hooks/useGetQuizStatus";
import { useQuestionsStore } from "store/questions";
import { useQuizStore } from "store/quiz";

export const ResultsTable = () => {
  const { quizId, resultId } = useParams();

  const { setQuizStatus, title, description } = useQuizStore();

  const [status, setStatus] = React.useState<"loading" | "error" | "resolved">(
    "loading"
  );

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!title || !description) {
      navigate(`/${quizId as string}`);
    }
  }, []);

  React.useEffect(() => {
    if (title) {
      document.title = title;
    }
    if (description) {
      const metaDescription = document.head.querySelector(
        'meta[name="description"]'
      );
      metaDescription?.setAttribute("content", description);
    }
  }, [title, description]);

  return (
    <>
      <DetailedResults/>
    </>
  );
};

import { api } from "services";

interface PostAnswerResponseProps {
  quizCode: string;
  quizCodeResult: string;
  rightAnswers: number;
  totalQuestions: number;
}

interface Answer {
  question_id: string;
  answer_id: string;
}

interface PostAnswerData {
  questions: Answer[];
  email: string | null;
}

interface CorrectAnswer {
  number: number;
  question: string;
  answer: string;
}

export interface Category {
  name: string;
  icon: string;
  rightAnswers: number;
  totalQuestions: number;
  correctAnswers: CorrectAnswer[];
}

interface PostAnswerResponseProps {
  resultText: string;
  rightAnswers: number;
  wrongAnswers: number;
  totalQuestions: number;
  resultCode: string;
  categories: Category[];
}

const postAnswers = (quizId: string, data: PostAnswerData) => {
  const postAnswers = api.post<PostAnswerResponseProps>(
    `/quiz/${quizId}/questions/`,
    data
  );
  return postAnswers;
};

export { postAnswers };

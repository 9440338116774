import { Box, LinearProgress } from "@mui/material";
import { useQuestionsStore } from "store/questions";
import {
  HeaderContainer,
  InfosContainer,
  LogoContainer,
  ProgressQuantity,
  QuizResultsContainer,
  Title,
} from "./styles";
import { calcProgress } from "utils/calcProgress";
import { useQuizStore } from "store/quiz";
import { ResultsBox } from "components/ResultsBox";

export const Header = () => {
  const { answers, totalQuestions } = useQuestionsStore();
  const { logo, title } = useQuizStore();
  return (
    <HeaderContainer>
      <InfosContainer>
        <LogoContainer>
          <img
            src={logo}
            alt="Full Cycle Learning Experience Logo"
            title="Full Cycle Learning Experience"
          />
          <Title>{title}</Title>
        </LogoContainer>
        <QuizResultsContainer>
          <ProgressQuantity>{`${answers} / ${totalQuestions}`}</ProgressQuantity>
        </QuizResultsContainer>
      </InfosContainer>
      <Box component="div">
        <LinearProgress
          variant="determinate"
          value={calcProgress(totalQuestions, answers)}
          sx={{
            borderRadius: "8px",
            minHeight: "16px",
            mt: "24px",
            backgroundColor: "#252525",
          }}
        />
      </Box>
    </HeaderContainer>
  );
};

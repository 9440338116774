import { Header } from "components/Header";
import React, { useEffect, useState } from "react";
import { QuestionsContainer, QuizContanier } from "./styles";
import { Questions } from "components/Questions";
import { Loading } from "components/Loading";
import { Error } from "components/Error";
import { useQuestionsStore } from "store/questions";
import { useGetQuestions } from "services/hooks/useGetQuestions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetQuizStatus } from "services/hooks/useGetQuizStatus";
import { useQuizStore } from "store/quiz";
import { quizDominio } from "utils/constants";

export const Quiz = () => {
  const { setTotalQuestions } = useQuestionsStore();
  const { setQuizStatus, title, description } = useQuizStore();

  const { quizId } = useParams<{ quizId: string }>();

  const { loading: loadingStatus, request: requestStatus } = useGetQuizStatus(
    quizId as string
  );

  const { loading, request } = useGetQuestions(quizId as string);

  const [questions, setQuestions] = useState<any>([]);

  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (title) {
      document.title = title;
    }
    if (description) {
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      metaDescription?.setAttribute("content", description);
    }
  }, [title, description]);

  const navigate = useNavigate();

  useEffect(() => {
    requestStatus()
      .then((data) => {
        if (!data.active) {
          navigate(`${quizDominio as string}/${quizId}`);
        } else {
          setQuizStatus(data.title, data.logo, data.description, data.url, {
            isActive: data.eventAdvertising.isActive,
            text: data.eventAdvertising.text,
            formId: data.eventAdvertising.formId,
            formPortalId: data.eventAdvertising.formPortalId,
          });
        }
      })
      .catch((error) => {
        setError(true);
      });
    request()
      .then((data) => {
        setQuestions(data);
        setTotalQuestions(data.questions.length);
      })
      .catch((e) => {
        setError(true);
      });
  }, []);

  return (
    <QuizContanier>
      <Header />
      <QuestionsContainer>
        {loading && <Loading message="Carregando questões..." />}
        {questions.questions && questions.questions.length > 0 && (
          <Questions questions={questions.questions} />
        )}
        {error && <Error message="Ops...Algo errado aconteceu" />}
      </QuestionsContainer>
    </QuizContanier>
  );
};
